import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { PortableText } from "@portabletext/react"
import { GatsbyImage } from "gatsby-plugin-image"
import { getGatsbyImageData } from "gatsby-source-sanity"
import Layout from "../components/layout"
import BlogHeader from "../components/blogHeader"
import { useAnimation, motion } from "framer-motion"
import { useInView } from "react-intersection-observer"
import { Link } from "gatsby"
import { BiBriefcase, BiMapAlt, BiInfoCircle } from "react-icons/bi"

const motionSection = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1,
      staggerChildren: 0.15,
      staggerDirection: 1,
      // delay: 0.3,
      ease: [0.16, 1, 0.3, 1],
      // when: "beforeChildren",
    },
  },
}

const sanityConfig = {
  projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  dataset: process.env.GATSBY_SANITY_PROJECT_DATASET,
  token: process.env.GATSBY_SANITY_TOKEN,
}

const CareerPostTemplate = props => {
  const { data } = props
  const post = data && data.post

  console.log("data", data)
  console.log("post", post)

  const controls = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])
  return (

    <Layout pageSeo={post}>
      <BlogHeader data={post} />

      <motion.section
        className="section p-6 lg:p-12 grid lg:grid-cols-6 gap-6 lg:gap-0 bg-stone-100"
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={motionSection}
      >
        <div className="lg:col-span-2 text-sm">
          <div className="p-6 rounded-lg shadow-sm shadow-cyan-100/40 bg-white text-black sticky top-0">
            <h3 className="h3 mb-2">Details</h3>
            <ul className=" divide-y divide-cyan-500/20 divide-y-4 mb-8">
              <li className="py-3 flex gap-2 items-center">
                {" "}
                <BiBriefcase fill="#3cc4f1" className="h-4 w-4" /> {post.jobType}
              </li>
              <li className="py-3 flex gap-2 items-center">
                <BiMapAlt fill="#3cc4f1" className="h-4 w-4" /> {post.jobLocation}
              </li>
              <li className="py-3 flex gap-2 items-center">
                <BiInfoCircle fill="#3cc4f1" className="h-4 w-4" /> {post.jobPay}
              </li>
            </ul>
            <div className="prose text-black">
              <h2 className="h3">Submit your resume</h2>
              <a
                href={
                  "mailto:info@phconsultingmedia.com?subject=Job%20application:%20" +
                  post.title
                }
                className="btn-secondary"
              >
                Apply now
              </a>
            </div>
          </div>
        </div>

        <div className="prose prose-xl text-black lg:pl-8 lg:col-span-4">
          <PortableText
            value={post._rawPortableText}
            components={{
              types: {
                image: ({ value }) => (
                  <GatsbyImage
                    image={getGatsbyImageData(
                      value.asset._ref,
                      { maxWidth: 1024 },
                      sanityConfig
                    )}
                  />
                ),
              },
            }}
            onMissingComponent={false}
          />
        </div>
      </motion.section>
    </Layout>
  )
}

export default CareerPostTemplate

export const query = graphql`
  query CareerPostTemplateQuery($id: String!) {
    post: sanityCareers(id: { eq: $id }) {
      title
    slug {
      current
    }
    _rawPortableText
    excerpt
    jobLocation
    jobPay
    jobType
    featuredImage {
      asset {
        altText
        gatsbyImageData
      }
    }
    _key
    _type
    metaDesc
    metaTitle
    }
  }
`
